const Macedonia = {
    mk: {
        translation: {
            rentalAgreement: "Договор за изнајмување",
            billNo: "Број на сметка",
            rentalAgreementNumber: "Број на договор за изнајмување",
            company: "Компанија",
            address: "Адреса",
            city: "Град",
            operatorName: "Име на операторот",
            phone: "Телефон",
            email: "Е-пошта",
            customer: "Купувач",
            name: "Име",
            country: "Држава",
            passport: "Пасош",
            drivingLicence: "Возачка дозвола",
            vehicle: "Возило",
            category: "Категорија",
            registrationNumber: "Регистарски број",
            color: "Боја",
            pickUp: "Преземање",
            date: "Датум",
            time: "Време",
            return: "Враќање",
            crossingBorders: "Преминување на граници",
            additionalDrivers: "Дополнителни возачи",
            termsConditions:
                "Со потпишување на овој договор за изнајмување, купувачот се согласува дека ги прочитал и прифатил условите и одредбите напишани на задната страна на договорот.",
            agencySignature: "Потпис на агенцијата",
            customerSignature: "Потпис на купувачот",
            damageExcess: "Штета повеќе",
            additionalDriversTotal: "Вкупно дополнителни возачи",
            crossingBordersTotal: "Вкупно преминување на граници",
            babySeatTotal: "Вкупно детско седиште",
            gpsTotal: "Вкупно GPS",
            insurancePremiumCoverage: "(Премиум покритие)",
            rentTotal: "Вкупно изнајмување",
            deposit: "Депозит",
            total: "Вкупно",
            vehicleDetails:
                "ŠKODA OCTAVIA J03-A-458 ОД: 11/06/2024 ДО: 21/06/2024",
            vehicleWashed: "Возилото е измиено",
            firstAid: "Прва помош",
            spareTyre: "Резервна гума",
            wheelCaps: "Капи за тркала",
            chains: "Синџири",
            fireExtinguisher: "Апарати за гасење пожар",
            carJack: "Крика за автомобил",
            lightBulbs: "Сијалици",
            pickUpVehicleCondition: "СОСТОЈБА НА ВОЗИЛОТО ПРИ ПРЕЗЕМАЊЕ",
            fuel: "ГОРИВО",
            returnVehicleCondition: "СОСТОЈБА НА ВОЗИЛОТО ПРИ ВРАЌАЊЕ",
            secretKey: "ТАЕН КЛУЧ",
            from: "Од",
            to: "До",
            cardNumber: "Број на картичка",
        },
    },
};

export default Macedonia;
