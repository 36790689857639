import React, { useContext } from "react";
import PdfContext from "../../Context/PdfContext";
import HorizontalDivider from "../HorizontalDivider";
import { useTranslation } from "react-i18next";

interface CreditCardInterface {
    value: string;
}

const CreditCardAggrement = ({ value }: CreditCardInterface) => {
    const { t } = useTranslation();
    const { creditCardInput } = useContext(PdfContext);
    const { cardNumber, cvv, expireDate } = creditCardInput.values;

    return (
        <>
            <div>
                <p
                    className={"text-[10px] ql-editor legal p-0 "}
                    dangerouslySetInnerHTML={{ __html: value }}
                ></p>
                <div className={"mt-3 mb-3 flex justify-between text-[10px]"}>
                    <p className={"text-[12px]"}>
                        {t("cardNumber")}: {cardNumber || "N/A"}
                    </p>
                    <p className={"text-[12px]"}>CVV: {cvv || "N/A"}</p>
                    <p className={"text-[12px]"}>
                        {t("date")}: {expireDate || "N/A"}{" "}
                    </p>
                </div>
            </div>
            <HorizontalDivider classes={"mt-2 mb-2"} />
        </>
    );
};

export default CreditCardAggrement;
