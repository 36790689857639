import React from "react";
import useCompany from "../../Hooks/CompanyHooks/useCompany";

const PdfTermsConditions = ({ value }) => {
    return (
        <>
            <div
                className={
                    "terms-conditions p-1 relative md:z-[-1]  ql-container ql-snow"
                }
            >
                <div
                    className={"ql-editor"}
                    dangerouslySetInnerHTML={{
                        __html: value,
                    }}
                ></div>
            </div>
        </>
    );
};

export default PdfTermsConditions;
