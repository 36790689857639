import { Divider, Space, Table, TableProps, Tag } from "antd";
import React, { ReactNode } from "react";
import {
    IntegrationPeriod,
    IntegrationPeriodRules,
} from "Types/IntegrationTypes";
import moment from "moment";
import UpdatePeriodModal from "../Modal/PeriodModals/UpdatePeriodModal";
import DeletePeriodModal from "../Modal/PeriodModals/DeletePeriodModal";
import CreateRuleModal from "../Modal/RulesModals/CreateRuleModal";
import { ArrowRightOutlined } from "@ant-design/icons";
import UpdateRuleModal from "../Modal/RulesModals/UpdateRuleModal";
import DeleteRuleModal from "../Modal/RulesModals/DeleteRuleModal";

interface PeriodListPropTypes {
    update: () => void;
    periods: IntegrationPeriod[];
    currency: string;
}

interface DataType {
    key: string;
    rowNumber: number;
    from: string;
    to: string;
    price: string;
    rules: IntegrationPeriodRules[];
    currency: string;
    update: () => void;
}

const columns: TableProps<DataType>["columns"] = [
    {
        title: "#",
        dataIndex: "rowNumber",
        key: "rowNumber",
    },
    {
        title: <small className={"uppercase"}>From</small>,
        dataIndex: "from",
        key: "from",
        render: (_, record) => {
            return (
                <Tag
                    className={"font-bold uppercase"}
                    color={"geekblue"}
                >{`${moment(record.from).format("D")} ${moment(
                    record.from,
                ).format("MMMM - YYYY")}`}</Tag>
            );
        },
    },
    {
        title: <small className={"uppercase"}>To</small>,
        dataIndex: "to",
        key: "to",
        render: (_, record) => {
            return (
                <Tag
                    className={"font-bold uppercase"}
                    color={"geekblue"}
                >{`${moment(record.to).format("D")} ${moment(record.to).format(
                    "MMMM - YYYY",
                )}`}</Tag>
            );
        },
    },
    {
        title: <small className={"uppercase"}>Price</small>,
        key: "price",
        dataIndex: "price",
        render: (_, record) => {
            return (
                <p>
                    {record.price} {record.currency}
                </p>
            );
        },
    },
    {
        title: <small className={"uppercase"}>ACTIONS</small>,
        key: "action",
        align: "center",
        render: (_, record) => (
            <div className={"flex gap-2 justify-end"}>
                <UpdatePeriodModal
                    update={record.update}
                    data={{
                        period_id: record.key,
                        date_from: record.from,
                        date_to: record.to,
                        price: record.price,
                    }}
                />
                <DeletePeriodModal
                    update={record.update}
                    periodId={record.key}
                />
                <CreateRuleModal update={record.update} periodId={record.key} />
            </div>
        ),
    },
];

const PeriodList = ({ update, periods, currency }: PeriodListPropTypes) => {
    return (
        <>
            <Table
                className={"md:overflow-auto"}
                columns={columns}
                size={"small"}
                dataSource={periods?.map((period, index) => {
                    let rowNumber = index + 1;
                    return {
                        key: period.uuid,
                        rowNumber: rowNumber,
                        from: period.date_from,
                        to: period.date_to,
                        price: period.price,
                        currency: currency,
                        rules: period.rules,
                        update: update,
                    };
                })}
                pagination={{ pageSize: 8, size: "small" }}
                expandable={{
                    expandedRowRender: (record) => {
                        return record.rules?.map((rule, index) => {
                            return (
                                <div key={rule.uuid}>
                                    {index > 0 && (
                                        <Divider className={"mt-2 mb-2"} />
                                    )}
                                    <div
                                        className={
                                            "flex gap-3 items-center justify-between"
                                        }
                                    >
                                        <div
                                            className={
                                                "flex p-2 gap-5 items-center"
                                            }
                                        >
                                            <p className={"uppercase w-44"}>
                                                Duration Days From:{" "}
                                                {rule.duration_from}
                                            </p>
                                            <ArrowRightOutlined />
                                            <p className={"uppercase w-44"}>
                                                Duration Days To:{" "}
                                                {rule.duration_to}
                                            </p>
                                            <p className={"uppercase w-44"}>
                                                Price: {rule.price}{" "}
                                                {record.currency}
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                "flex gap-3 items-center"
                                            }
                                        >
                                            <UpdateRuleModal
                                                update={record.update}
                                                data={{
                                                    duration_from:
                                                        rule.duration_from,
                                                    duration_to:
                                                        rule.duration_to,
                                                    ruleId: rule.uuid,
                                                    price: rule.price,
                                                }}
                                            />
                                            <DeleteRuleModal
                                                update={record.update}
                                                ruleId={rule.uuid}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        });
                    },
                    rowExpandable: (record) => record.rules.length > 0,
                }}
            />
        </>
    );
};

export default PeriodList;
