import React from "react";
import VehicleCondition from "../../Assets/Images/vehicle-condition.png";
import HorizontalDivider from "../HorizontalDivider/HorizontalDivider";
import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";

const PdfVehicleConditions = ({ from, to, carName, licencePlate }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={"vehicle-condition p-3"}>
                <div className={"flex justify-between sm:flex-wrap"}>
                    <p className={"uppercase"}>{carName}</p>
                    <p className={"uppercase"}>{licencePlate}</p>
                    <p className={"uppercase"}>
                        {t("from")}: {from}
                    </p>
                    <p className={"uppercase"}>
                        {t("to")}: {to}
                    </p>
                </div>
                <HorizontalDivider classes={"mt-2 mb-3"} />
                <div className={"flex gap-5 flex-wrap"}>
                    <div>
                        <div className="form-control">
                            <Checkbox
                                defaultChecked={false}
                                disabled={true}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                {t("vehicleWashed")}
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                defaultChecked={false}
                                disabled={true}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                {t("firstAid")}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="form-control">
                            <Checkbox
                                defaultChecked={false}
                                disabled={true}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                {t("spareTyre")}
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                defaultChecked={false}
                                disabled={true}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                {t("wheelCaps")}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="form-control">
                            <Checkbox
                                defaultChecked={false}
                                disabled={true}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                {t("chains")}
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                defaultChecked={false}
                                disabled={true}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                {t("fireExtinguisher")}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="form-control">
                            <Checkbox
                                defaultChecked={false}
                                disabled={true}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                {t("carJack")}
                            </span>
                        </div>
                        <div className="form-control">
                            <Checkbox
                                defaultChecked={false}
                                disabled={true}
                                autoFocus={true}
                                className="checkbox checkbox-success"
                            />
                            <span className="label-text text-secondary ml-1">
                                {t("lightBulbs")}
                            </span>
                        </div>
                    </div>
                </div>
                <HorizontalDivider classes={"mt-2"} />
                <p className={"font-medium text-[10] uppercase"}>
                    {t("pickUpVehicleCondition")}
                </p>
                <HorizontalDivider classes={"mb-1"} />
                <div className={"flex justify-between items-center"}>
                    <div className={"w-[500px]"}>
                        <img src={VehicleCondition} alt={"vehicle"} />
                    </div>
                    <div>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            1/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            2/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            3/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            4/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            5/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            6/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            7/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            8/8
                        </p>
                        <p>{t("fuel")}</p>
                    </div>
                </div>
                <HorizontalDivider classes={"mt-1 mb-1"} />
                <div className={"flex justify-between"}>
                    <div>
                        <p>{t("agencySignature")}</p>
                        <HorizontalDivider classes={"mt-5"} />
                    </div>
                    <div>
                        <p>{t("customerSignature")}</p>
                        <HorizontalDivider classes={"mt-5"} />
                    </div>
                </div>
                <HorizontalDivider classes={"mt-5"} />
                <p className={"font-medium text-[10] uppercase"}>
                    {t("returnVehicleCondition")}
                </p>
                <HorizontalDivider classes={"mb-1"} />

                <div className={"flex justify-between items-center"}>
                    <div className={"w-[500px]"}>
                        <img src={VehicleCondition} alt={"vehicle"} />
                    </div>
                    <div>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            1/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            2/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            3/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            4/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            5/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            6/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            7/8
                        </p>
                        <p className={"p-1 rounded-full border-[1px] mb-1"}>
                            8/8
                        </p>
                        <p>{t("fuel")}</p>
                    </div>
                </div>
                <HorizontalDivider classes={"mt-1 mb-1"} />
                <div className={"flex justify-between"}>
                    <div>
                        <p>{t("agencySignature")}</p>
                        <HorizontalDivider classes={"mt-5"} />
                    </div>
                    <div>
                        <p>{t("customerSignature")}</p>
                        <HorizontalDivider classes={"mt-5"} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PdfVehicleConditions;
